import "nprogress/nprogress.css";

import * as constants from "./constants";

import * as Sentry from "@sentry/browser";
Sentry.init({
  dsn: constants.SENTRY_DSN,
  environment: (<any>window).ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration()],
  release: (<any>window).GIT_COMMIT_HASH,
  tracesSampleRate: 1.0,
});

import { Issue, Manifest, processManifest } from "./manifest";
import Newspaper from "./newspaper";
import { initSearchSuggestions } from "./search";
import * as utils from "./utils";

import hotkeys from "hotkeys-js";
import * as _ from "lodash";
import * as NProgress from "nprogress";
import Plausible from "plausible-tracker";
import romanize from "romanize";
import "url-search-params-polyfill";

var newspaper: Newspaper | null = null;

async function route() {
  var hash = utils.parseHash();
  var fixedPageNumber = newspaper!.fixPageNumber(hash.page);
  if (hash.page != fixedPageNumber) {
    hash.page = fixedPageNumber;
    window.location.hash = utils.buildHash(hash);
  }

  await newspaper!.requestPage(hash.page - (hash.page % 2));
}

function updateSelects(
  manifest: Manifest,
  yearSelect: HTMLDivElement,
  issueSelect: HTMLDivElement,
  year: number,
  activeIssue: number | null
) {
  yearSelect.firstElementChild!.innerHTML = "" + year;
  let listElement = issueSelect.lastElementChild!;
  listElement.innerHTML = "";

  yearSelect.dataset["selected"] = "" + year;

  manifest.get(year)!.forEach((issue, number) => {
    let issueElement = document.createElement("a") as HTMLAnchorElement;
    issueElement.classList.add("issue-link");
    if (activeIssue == number) {
      issueElement.classList.add("active");
    }
    issueElement.dataset["issue"] = "" + number;

    var date = "";
    if (issue.date != null) {
      date +=
        " (" +
        issue.date[0] +
        " " +
        romanize(issue.date[1]) +
        " " +
        issue.date[2] +
        " r.)";
    }
    issueElement.addEventListener("click", () => {
      issueSelect.lastElementChild!.classList.toggle("invisible");
      var slug = window.location.pathname.split("/")[1];
      var year = yearSelect.dataset["selected"];
      window.location.href = `/${slug}/${year}/${number}`;
    });

    issueElement.innerText = "" + number + date;
    listElement.appendChild(issueElement);
  });

  if (activeIssue == null) {
    issueSelect.firstElementChild!.innerHTML = "?";
    issueSelect.lastElementChild!.classList.remove("invisible");
  } else {
    issueSelect.firstElementChild!.innerHTML = "" + activeIssue;
  }
}

function loadPage(delta: number) {
  if (!newspaper!.isClickable()) {
    return;
  }
  var newTargetPageNumber = newspaper!.targetPageNumber + delta;
  if (newTargetPageNumber >= 0 && newTargetPageNumber <= newspaper!.pageCount) {
    var hash = utils.parseHash();
    hash.page = newTargetPageNumber;
    window.location.hash = utils.buildHash(hash);
  }
}

function updateWheelZoomState(newspaper: Newspaper) {
  const enabled = Array.from(
    document.querySelectorAll("a.dropdown-link")
  ).every((element) => {
    return element.nextElementSibling!.classList.contains("invisible");
  });
  newspaper.enableWheelZoom(enabled);
}

async function start() {
  var manifest = processManifest(window["MANIFEST"]!);
  var newspaperElement = document.getElementById("newspaper") as HTMLDivElement;
  var searchElement = document.getElementById("search") as HTMLDivElement;

  const newspaperSelect = document.getElementById(
    "newspaper-select"
  )! as HTMLDivElement;
  const yearSelect = document.getElementById("year-select")! as HTMLDivElement;
  const issueSelect = document.getElementById(
    "issue-select"
  )! as HTMLDivElement;
  updateSelects(
    manifest,
    yearSelect,
    issueSelect,
    +document.body.dataset.year!,
    +document.body.dataset.issue!
  );

  if (utils.isMobile()) {
    if (newspaperElement != null) {
      newspaperElement.innerText =
        "Strona nie działa na małych ekranach takich jak w telefonie.";
      return;
    }
  }

  initSearchSuggestions(
    manifest,
    +document.body.dataset.dlibraId!,
    document.body.dataset.slug,
    searchElement.querySelector("input"),
    searchElement.querySelector(".suggestions")
  );

  newspaper = new Newspaper(
    newspaperElement,
    document.body.dataset.slug!,
    new Issue(
      +document.body.dataset.year!,
      +document.body.dataset.issue!,
      null,
      +document.body.dataset.width!,
      +document.body.dataset.height!,
      +document.body.dataset.pageCount!
    )
  );
  document.querySelectorAll("a.dropdown-link").forEach((element) => {
    const dropdown = element.nextElementSibling!;
    element.addEventListener("click", () => {
      document.querySelectorAll("a.dropdown-link").forEach((otherElement) => {
        if (otherElement != element) {
          let otherDropdown = otherElement.nextElementSibling!;
          otherDropdown.classList.add("invisible");
        }
      });
      dropdown.classList.toggle("invisible");
      updateWheelZoomState(newspaper!);
    });
  });
  document.querySelectorAll("a.newspaper-link").forEach((element) => {
    var slug = (element as HTMLElement).dataset["slug"]!;
    element.addEventListener("click", () => {
      window.location.href = `/${slug}`;
    });
  });
  document.querySelectorAll("a.year-link").forEach((element) => {
    var year = +(element as HTMLElement).dataset["year"]!;
    element.addEventListener("click", () => {
      yearSelect.lastElementChild!.classList.toggle("invisible");
      updateSelects(manifest, yearSelect, issueSelect, year, null);
      updateWheelZoomState(newspaper!);
    });
  });

  yearSelect.addEventListener("click", (event) => {
    event.stopPropagation();
  });
  issueSelect.addEventListener("click", (event) => {
    event.stopPropagation();
  });
  newspaperSelect.addEventListener("click", (event) => {
    event.stopPropagation();
  });
  document.body.addEventListener("click", () => {
    yearSelect.lastElementChild!.classList.add("invisible");
    issueSelect.lastElementChild!.classList.add("invisible");
    newspaperSelect.lastElementChild!.classList.add("invisible");
    updateWheelZoomState(newspaper!);
  });

  newspaper.on("load:start", () => {
    NProgress.start();
  });
  newspaper.on("load:progress", (progress: number | undefined) => {
    if (progress === undefined) {
      NProgress.inc();
    } else {
      NProgress.set(progress);
    }
  });
  newspaper.on("load:complete", () => {
    NProgress.done();
  });

  hotkeys("left", (_event, _handler) => {
    loadPage(-2);
  });
  hotkeys("right", (_event, _handler) => {
    loadPage(2);
  });
  await newspaper.load();
  document
    .getElementById("page0")!
    .addEventListener("click", loadPage.bind(null, -2));
  document
    .getElementById("page1")!
    .addEventListener("click", loadPage.bind(null, +2));

  window.addEventListener("hashchange", route);
  await route();
}

export const plausible = Plausible({
  domain: "merkuriusze.pl",
  hashMode: true,
});

start().then(() => {
  console.log("Załadowane.");
  plausible.trackPageview();
});
